article,
.article{
  h2{
    margin: var(--spacing6) 0 var(--spacing4) 0;

    color: var(--black);

    font-weight: 700;

    font-size: var(--fontSize4);
    font-family: var(--secondaryFont);
    line-height: 1.3em;
    @media (max-width: 800px){
      font-size: var(--fontSize2);
    }
  }

  h3{
    margin: var(--spacing5) 0 var(--spacing3) 0;

    color: var(--black);

    font-weight: 700;

    font-size: var(--fontSize3);
    font-family: var(--secondaryFont);
    line-height: 1.3em;
    @media (max-width: 800px){
      font-size: var(--fontSize2);
    }
  }

  h4{
    margin: var(--spacing5) 0 var(--spacing3) 0;

    color: var(--black);

    font-weight: 700;

    font-size: var(--fontSize2);
    font-family: var(--secondaryFont);
    line-height: 1.3em;
  }

  p,
  ul,
  ol{
    margin: 0 0 var(--spacing5) 0;

    line-height: var(--lineHeight);

    &:last-child{
      margin-bottom: 0;
    }
  }
  figure{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--spacing5) 0;

    img{
      max-width: 100%;
      object-fit: cover;
      object-position: center center;
      overflow: hidden;

      border: 0 solid transparent;
      border-radius: var(--curve);
    }

    &.size-large{
      img{
        width: 100%;
        height: auto;
      }
    }


    figcaption{
      width: 100%;

      font-style: italic;

      text-align: center;
    }
  }
  iframe{
    width: 100%;
    max-width: 100%;
    margin-bottom: var(--spacing5);
    overflow: hidden;

    border: 0 solid transparent;
    border-radius: var(--curve);
  }

  img{
    max-width: 100%;
  }

  blockquote{
    margin: var(--spacing5) 0;
    padding: var(--spacing5);

    background-color: #dddddd;

    border-left: 3px solid var(--green);
  }

  pre{
    position: relative;

    padding: var(--spacing7) var(--spacing5) var(--spacing5) var(--spacing5);

    background-color: hsla(var(--greenHsl), 0.05);
    border: 3px solid var(--green);
    code{
      color: var(--light-text-color);
      white-space: pre-wrap;

      &::after{
        position: absolute;
        top: 0;
        left: 0;

        padding: var(--spacing3);

        color: var(--white);
        font-weight: 700;

        font-size: var(--fontSize1);
        font-family: var(--primaryFont);

        background-color: var(--green);
      }

      &.language-chatgpt4::after{content: "ChatGPT 4";}
      &.language-chatgpt3::after{content: "ChatGPT 3";}
      &.language-midjourney5::after{content: "Midjourney 5";}
      &.language-midjourney4::after{content: "Midjourney 4";}
      &.language-midjourney3::after{content: "Midjourney 3";}
      &.language-stablediffusion::after{content: "Stable Diffusion";}
    }
  }

  table{
    margin: 0 auto;

    border: 1px solid var(--green);
    border-collapse: true;
    border-spacing: 0;

    thead{
      color: var(--iaaWhite);

      background: var(--green);
    }

    td,
    th{
      padding: var(--spacing4) var(--spacing5);
    }

    tr:nth-child(2n+1){
      background: #00000022;
    }

    &:not(:last-child){
      margin-bottom: var(--spacing5);
    }
  }

  hr{
    height: 1px;
    margin: var(--spacing6) 0;

    background: var(--gray);
    border: none;
  }
}
