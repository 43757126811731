@import "./_fonts.scss";
@import "./_variables.scss";
@import "./_headers.scss";
@import "./_article.scss";
@import "./_section.scss";
@import "./_mixins.scss";
@import "./_link.scss";


/*general*/
html{
  overflow: hidden;
  overflow-y: auto;
}
body{
  position: relative;

  color: var(--white);
  font-family: var(--primaryFont);

  background: var(--blue);
  &.noscroll{
    max-height: 100vh;
    overflow: hidden;

    touch-action: none;
  }

  .light{
    color: var(--black);
  }

  .dark{
    color: var(--white);
  }
}

body,
html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  font-size: 16px;

  font-family: var(--primaryFont);
}

.invis{
  opacity: 0;
}
.initialInvis{
  visibility: hidden;
}
.hidden{
  visibility: hidden;
}

.d-none{
  display: none;
}

p{
  line-height: 1.6em;

  &:first-child{
    margin-top: 0;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

main{
  position: relative;

  min-height: calc(100vh - 445px); //445px = height of the navbar and the footer for wide screen
}


::selection{
  color: var(--black);

  background-color: var(--white);

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

.light *::selection{
  color: var(--white);

  background-color: var(--black);

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

/*width*/
::-webkit-scrollbar{
  width: 1rem;
}

/*Track*/
::-webkit-scrollbar-track{
  background: var(--grayDark);
}

/*Handle*/
::-webkit-scrollbar-thumb{
  background: var(--green);
}

/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: var(--greenLight);
}

*{
  -webkit-tap-highlight-color: transparent;
}

ol,
ul{
  line-height: var(--lineHeight);
}
