/*latin*/
@font-face{
  font-weight: 300;
  font-family: "Montserrat";
  font-style: italic;
  src: url("/fonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 400;
  font-family: "Montserrat";
  font-style: italic;
  src: url("/fonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Montserrat";
  font-style: italic;
  src: url("/fonts/JTUQjIg1_i6t8kCHKm459WxRyS7m.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 200;
  font-family: "Montserrat";
  font-style: normal;
  src: url("/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 400;
  font-family: "Montserrat";
  font-style: normal;
  src: url("/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Montserrat";
  font-style: normal;
  src: url("/fonts/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
