a:not(.custom){
  --fg: var(--white);
  --bg: var(--black);

  color: var(--fg);
  font-weight: 700;

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

  svg path{
    transition: fill 0.1s ease-in-out;

    fill: var(--fg);
  }

  &:hover{
    color: var(--bg);

    background-color: var(--fg);

    svg path{
      fill: var(--bg);
    }
  }
}


.light a:not(.custom){
  --fg: var(--black);
  --bg: var(--white);
}


.dark a:not(.custom){
  --fg: var(--white);
  --bg: var(--black);
}
